import { useLocalStorage } from './useLocalStorage'
import { MediaQuery, Group, Stack, Box, Button, MantineProvider, Text, Divider, Select } from '@mantine/core';
import Calendar from './Calendar'
import { calendarGroups } from './calendars'
import { forwardRef } from 'react';
import { IconCheck } from '@tabler/icons';

const FilterKey = 'calendar-filter'
const ShiftsKey = 'show-shifts'
const ShowCanceledKey = 'show-canceled'

const allCals = calendarGroups.map(group => group.title).sort()

function App() {
  const [filters, setFilters] = useLocalStorage(FilterKey, allCals)
  const [shifts, setShifts] = useLocalStorage(ShiftsKey, 'All')
  const [showCanceled, setShowCanceled] = useLocalStorage(ShowCanceledKey, true)

  const toggleCanceled = () => setShowCanceled(!showCanceled)

  let calendars = [];
  calendarGroups.filter(group => filters.includes(group.title)).forEach(group => calendars = calendars.concat(group.calendars))

  const options = (
    <MediaQuery query="print" styles={{ display: 'none' }}>
      <div>
        <Group position='apart' px={4}>
          <Filters filters={filters} setFilters={setFilters} />
          <Shifts shifts={shifts} setShifts={setShifts} />

          <MediaQuery smallerThan={1000} styles={{ display: 'none' }}>
            <Stack spacing={0}>
              <Text size='sm' weight={500}>Canceled Events</Text>
              <Button onClick={toggleCanceled} >{showCanceled ? 'Hide' : 'Show'} </Button>
            </Stack>
          </MediaQuery>
          <MediaQuery largerThan={1000} styles={{ display: 'none' }}>
            <Stack spacing={0}>
              <Text size='sm' weight={500}>Canceled</Text>
              <Button size='sm' onClick={toggleCanceled} >{showCanceled ? 'Hide' : 'Show'} </Button>
            </Stack>
          </MediaQuery>


        </Group>
        <Divider mt={16} />
      </div>
    </MediaQuery>
  )

  return (
    <MantineProvider theme={{
      primaryColor: 'calendarblue',
      colors: {
        'calendarblue': ["#E7EEFE", "#E7EEFE", "#E7EEFE", "#BBCFFC", "#8FAFFA", "#6390F8", "#3771F6", "#0B52F4", "#0942C3", "#073192", "#042162", "#021031"],
      },
    }} withGlobalStyles withNormalizeCSS>
      <Stack sx={{ height: '100%' }}>
        {options}
        <Box px={16} py={1} sx={{ flex: '1 1 0%' }}>
          <Calendar calendars={calendars} showCanceled={showCanceled} shifts={shifts} />
        </Box>
      </Stack>
    </MantineProvider>
  );
}

function Filters({ filters, setFilters }) {
  const clickedButton = title => {
    if (title === 'All') {
      setFilters([...allCals])
    } else if (title === 'None') {
      setFilters([])
    } else {
      const idx = filters.indexOf(title)
      let newFilter = [...filters]
      if (idx !== -1) {
        // remove from filters
        newFilter.splice(idx, 1)
      } else {
        // add to filters
        newFilter = newFilter.concat(title)
      }
      setFilters(newFilter)
      localStorage.setItem(FilterKey, JSON.stringify(newFilter))
    }
  }
  const buttons = [
    ...allCals.map(title => ({ title, selected: filters.includes(title) })),
    { title: 'All', special: true },
    { title: "None", special: true },
  ]

  return (
    <ButtonGroup label='Calendars' buttonData={buttons} clicked={clickedButton} multiSelect />
  )
}

function Shifts({ shifts, setShifts }) {
  const ShiftList = ['All', 'Morning', 'Afternoon', 'Evening']
  const buttons = ShiftList.map(shift => ({ title: shift, selected: shifts === shift }))
  const setShift = shift => setShifts(shift)
  return (
    <ButtonGroup label='Shift' buttonData={buttons} clicked={setShift} />
  )
}

function ButtonGroup({ buttonData, clicked, label, multiSelect }) {
  const buttons = buttonData.map((button, index) => {
    const clickedButton = () => clicked(button.title)
    return (<Button px={10} onClick={clickedButton} variant={button.special ? 'light' : (button.selected ? 'filled' : 'outline')} key={button.title} disabled={button.disabled}>{button.title}</Button>)
  })

  let smallContent

  if (multiSelect) {
    const select = item => clicked(item)
    const data = buttonData.map(({ title, selected }) => ({ label: title, value: title, selected }))
    data.push({ label, value: label, noDisplay: true })
    smallContent = (
      <Select
        value={label}
        label={label}
        data={data}
        itemComponent={SelectItem}
        w={120}
        maxDropdownHeight={1000}
        onChange={select}
        size='sm'
      />
    )
  } else {
    const handleSelect = title => clicked(title)
    const selected = buttonData.find(item => item.selected)
    smallContent = (
      <Select
        label={label}
        data={buttonData.map(item => item.title)}
        value={selected.title}
        onChange={handleSelect}
        w={115}
        size='sm'
      />
    )
  }

  return (
    <Stack spacing={5}>
      <MediaQuery smallerThan={1000} styles={{ display: 'none' }}>
        <div>
          {label && <Text size='sm' weight={500}>{label}</Text>}
          <Button.Group>
            {buttons}
          </Button.Group>
        </div>
      </MediaQuery>
      <MediaQuery largerThan={1000} styles={{ display: 'none' }}>
        <div>
          {smallContent}
        </div>
      </MediaQuery>
    </Stack>

  )
}

const SelectItem = forwardRef(
  ({ label, selected, onMouseDown, noDisplay }, ref) => {
    const handleClick = event => {
      onMouseDown(event)
    }
    if (noDisplay) {
      return null
    }
    return (
      <div ref={ref}>
        <Group p={4} noWrap onClick={handleClick} sx={{
          cursor: 'pointer',
          '&:hover': { backgroundColor: '#eee', },
        }}>
          <Box w={8}>
            {selected && <IconCheck size={16} />}
          </Box>
          <Text size='sm'>{label}</Text>
        </Group>
      </div>
    );
  }
);


export default App;
