export const calendarGroups = [
    {
        title: "Tech Front",
        calendars: ['photos2', 'slides', 'film8mm', 'negatives', 'bookScanner', 'audio', 'r2raudio', 'minidv', 'video8mm'],
    }, {
        title: "Tech Back",
        calendars: ['photos1', 'video', 'betamaxVideo'],
    }, {
        title: "Discovery",
        calendars: ['discovery'],
    }, {
        title: "Events",
        calendars: ['insideEvents', 'outsideEvents'],
    }, {
        title: "Classrooms",
        calendars: ['classroom1', 'classroom2', 'zoom'],
    }
]

export const calendarMap = {
    bookScanner: {
        url: 'https://calendar.google.com/calendar/ical/fsclehi.org_pm8atc4cc501mh4b5g9p5hgst0%40group.calendar.google.com/public/basic.ics',
        color: 'brown',
        textColor: 'white',
        title: 'Book Scanner'
    },
    audio: {
        url: 'https://calendar.google.com/calendar/ical/fsclehi.org_srn13vnkq9r18788pvf1gihev0%40group.calendar.google.com/public/basic.ics',
        color: 'aqua',
        title: 'Audio Cassette'
    },
    r2raudio: {
        url: 'https://calendar.google.com/calendar/ical/c_933935510d9da53ff259cf0f1ee4c549b8f6c4a1d94afd55c1f1102d9b85cf9e%40group.calendar.google.com/public/basic.ics',
        color: 'teal',
        textColor: 'white',
        title: 'Reel-to-Reel Audio'
    },
    classroom1: {
        url: 'https://calendar.google.com/calendar/ical/fsclehi.org_46n156fet0tf4q3kdlneambi3s%40group.calendar.google.com/public/basic.ics',
        color: 'green',
        textColor: 'white',
        title: 'Classroom 1'
    },
    classroom2: {
        url: 'https://calendar.google.com/calendar/ical/fsclehi.org_kraadrcj4kttof7j64gj953308%40group.calendar.google.com/public/basic.ics',
        color: 'lightgreen',
        title: 'Classroom 2'
    },
    discovery: {
        url: 'https://calendar.google.com/calendar/ical/fsclehi.org_als90nisdejjden98ru21kp5es%40group.calendar.google.com/public/basic.ics',
        color: 'orange',
        textColor: 'white',
        title: 'Discovery'
    },
    film8mm: {
        url: 'https://calendar.google.com/calendar/ical/c_disfouj6ivh41fmjb2dtu8bfp0%40group.calendar.google.com/public/basic.ics',
        color: 'dodgerblue',
        textColor: 'white',
        title: '8mm Film'
    },
    negatives: {
        url: 'https://calendar.google.com/calendar/ical/fsclehi.org_bbrrakm5ntc00hlfpk04kmndc4%40group.calendar.google.com/public/basic.ics',
        color: 'gray',
        textColor: 'white',
        title: 'Negative Scanner'
    },
    photos2: {
        url: 'https://calendar.google.com/calendar/ical/fsclehi.org_71laumkh6gihsch4b711q56mkc%40group.calendar.google.com/public/basic.ics',
        color: 'mediumblue',
        textColor: 'white',
        title: 'Photos Front'
    },
    photos1: {
        url: 'https://calendar.google.com/calendar/ical/fsclehi.org_3ilp8ph7unaod4ml6bjvqj86ts%40group.calendar.google.com/public/basic.ics',
        color: 'lightskyblue',
        textColor: '#444',
        title: 'Photos Back'
    },
    slides: {
        url: 'https://calendar.google.com/calendar/ical/fsclehi.org_hh947s7aklb7olv0abpiimhvis%40group.calendar.google.com/public/basic.ics',
        color: 'blueviolet',
        textColor: 'white',
        title: 'Slides'
    },
    video: {
        url: 'https://calendar.google.com/calendar/ical/fsclehi.org_i5rnd3fmtvvgg6tj0hl6bhho7g%40group.calendar.google.com/public/basic.ics',
        color: 'orangered',
        textColor: 'white',
        title: 'VHS Video'
    },
    video8mm: {
        url: 'https://calendar.google.com/calendar/ical/c_7ohjtjqqanl0necspmtq8cs5do%40group.calendar.google.com/public/basic.ics',
        color: 'salmon',
        title: '8mm Video'
    },
    zoom: {
        url: 'https://calendar.google.com/calendar/ical/c_ebfvvhkn8qcvtasr2ecjkfnc0g%40group.calendar.google.com/public/basic.ics',
        color: 'lawngreen',
        // textColor: 'white',
        title: 'Zoom Class'
    },
    insideEvents: {
        url: 'https://calendar.google.com/calendar/ical/fsclehi.org_8fk9qol55hj9iu555724i2kmkc%40group.calendar.google.com/public/basic.ics',
        color: 'plum',
        title: 'Inside Events'
    },
    outsideEvents: {
        url: 'https://calendar.google.com/calendar/ical/c_co4cpl3g2fnub7ckrjqmli8f08%40group.calendar.google.com/public/basic.ics',
        color: 'palegreen',
        title: 'Outside Events'
    },
    minidv: {
        url: 'https://calendar.google.com/calendar/ical/c_76htq6cicvg1hi495vvps1f2dk%40group.calendar.google.com/public/basic.ics',
        color: 'sandybrown',
        title: 'MiniDV Video'
    },
    betamaxVideo: {
        url: 'https://calendar.google.com/calendar/ical/c_eeb90d0b150ce0bb9ae9203b834cece6db1cc9a5dee5d6ff9a9ffdfc6a952dd1%40group.calendar.google.com/public/basic.ics',
        color: 'hotpink',
        title: 'Betamax Video'
    },
}

export const calendarColorMap = {}
Object.keys(calendarMap).forEach(cal => {
    const { title, color } = calendarMap[cal]
    return calendarColorMap[color] = title
})