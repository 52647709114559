export const messages = {
    Email: [
        {
            title: 'Final Reminders',
            subject: 'Lehi FamilySearch Center Discovery Reminder',
            content: `<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">
<html>
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <meta http-equiv="Content-Style-Type" content="text/css">
  <title></title>
  <meta name="Generator" content="Cocoa HTML Writer">
  <meta name="CocoaVersion" content="2113.6">
  <style type="text/css">
    p.p1 {margin: 0.0px 0.0px 0.0px 0.0px; font: 16.0px Helvetica; -webkit-text-stroke: #000000}
    p.p2 {margin: 0.0px 0.0px 0.0px 0.0px; font: 16.0px Helvetica; -webkit-text-stroke: #000000; min-height: 19.0px}
    p.p3 {margin: 0.0px 0.0px 0.0px 0.0px; font: 21.3px 'Times New Roman'; color: #fb0007; -webkit-text-stroke: #fb0007}
    p.p4 {margin: 0.0px 0.0px 0.0px 0.0px; font: 21.3px 'Times New Roman'; color: #fb0007; -webkit-text-stroke: #fb0007; min-height: 25.0px}
    p.p5 {margin: 0.0px 0.0px 0.0px 0.0px; font: 21.3px 'Times New Roman'; -webkit-text-stroke: #000000}
    p.p6 {margin: 0.0px 0.0px 0.0px 0.0px; font: 14.7px Helvetica; -webkit-text-stroke: #000000; min-height: 17.0px}
    p.p7 {margin: 0.0px 0.0px 0.0px 0.0px; font: 18.7px 'Times New Roman'; -webkit-text-stroke: #000000}
    p.p8 {margin: 0.0px 0.0px 13.0px 0.0px; font: 13.0px Helvetica; -webkit-text-stroke: #000000; min-height: 16.0px}
    p.p9 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: center; font: 16.0px 'Times New Roman'; -webkit-text-stroke: #000000}
    p.p10 {margin: 0.0px 0.0px 0.0px 0.0px; font: 16.0px 'Times New Roman'; color: #fb0007; -webkit-text-stroke: #fb0007}
    p.p11 {margin: 0.0px 0.0px 0.0px 0.0px; font: 16.0px 'Times New Roman'; -webkit-text-stroke: #000000}
    li.li7 {margin: 0.0px 0.0px 0.0px 0.0px; font: 18.7px 'Times New Roman'; -webkit-text-stroke: #000000}
    span.s1 {font-kerning: none}
    span.s2 {font-kerning: none; color: #000000; -webkit-text-stroke: 0px #000000}
    span.s3 {text-decoration: underline ; font-kerning: none; color: #094fd1; -webkit-text-stroke: 0px #094fd1}
    span.s4 {text-decoration: underline ; font-kerning: none}
    span.s5 {font-kerning: none; color: #fb0007; -webkit-text-stroke: 0px #fb0007}
    span.s6 {font: 18.7px 'Times New Roman'; text-decoration: underline ; font-kerning: none; color: #094fd1; -webkit-text-stroke: 0px #094fd1}
    ul.ul1 {list-style-type: disc}
  </style>
</head>
<body>
<p class="p1"><span class="s1">{name},</span></p>
<p class="p2"><span class="s1"></span><br></p>
<p class="p3"><span class="s1"><b>**************** FINAL REMINDERS *************</b></span></p>
<p class="p4"><span class="s1"><b></b></span><br></p>
<p class="p5"><span class="s1"><b>Discovery Reservation Date/Time: </b></span>{datetime}</p>
<p class="p5"><span class="s1"><b>Organization: </b></span>{organization}</p>
<p class="p3"><span class="s1"><b> </b></span></p>
<p class="p3"><span class="s2"><b> </b></span><span class="s1"><b>********** Make Sure Each Person has a FamilySearch Account Set up ***********</b></span></p>
<p class="p6"><span class="s1"></span><br></p>
<ul class="ul1">
  <li class="li7"><b></b><span class="s1"><b>IF SETTING UP A NEW FamilySearch ACCOUNT GO TO:</b></span></li>
</ul>
<p class="p8"><span class="s1"></span><br></p>
<p class="p7"><span class="s3"><a href="http://familysearch.org/"><b>FamilySearch.org</b></a></span><span class="s1"><b> and “Create Account”.  After you have set up your account make sure your tree is connected to your deceased ancestors.</b></span></p>
<p class="p6"><span class="s1"></span><br></p>
<ul class="ul1">
  <li class="li7"><b></b><span class="s1"><b>Note: A CHURCH LOGIN ACCOUNT </b></span><span class="s4"><b>MAY</b></span><span class="s1"><b> NOW OPTIONALLY BE USED TO LOGIN TO A FamilySearch ACCOUNT.  </b></span></li>
  <li class="li7"><b></b><span class="s1"><b>If you have not texted the age ranges of your group, please do it now to </b></span><span class="s5"><b>801-850-2595</b></span><span class="s1"><b>.</b></span></li>
</ul>
<p class="p8"><span class="s1"></span><br></p>
<p class="p9"><span class="s1"><b> </b></span></p>
<p class="p10"><span class="s1">******************************************************************************</span></p>
<p class="p5"><span class="s4"><b>HAVE THE PARTICIPANTS BRING:</b></span></p>
<p class="p7"><span class="s1">1. FamilySearch Username</span></p>
<p class="p7"><span class="s1">2. FamilySearch Password</span></p>
<p class="p7"><span class="s1">3. Cell Phone if they have one</span></p>
<p class="p7"><span class="s1"> </span></p>
<p class="p10"><span class="s1">******************************************************************************</span></p>
<p class="p5"><span class="s4"><b>IF THERE ARE YOUTH 12 AND YOUNGER HAVE THEM CREATE FAMILY SEARCH ACCOUNT WITH A PARENT</b></span></p>
<p class="p7"><span class="s1">1.  Parent Permission is required to set up the FamilySearch account.</span></p>
<p class="p7"><span class="s1">2.  So, please have them set up their account with their parents before the activity DATE.</span></p>
<p class="p7"><span class="s1">3.  Go to <a href="http://familysearch.org/"><span class="s6">FamilySearch.org</span></a> and click on create account.</span></p>
<p class="p7"><span class="s1">4.  After you have the account set up, please CONNECT YOUR TREE to your deceased ancestors.</span></p>
<p class="p6"><span class="s1"></span><br></p>
<p class="p10"><span class="s1">******************************************************************************</span><span class="s2"> </span></p>
<p class="p5"><span class="s4"><b>IF YOU NEED TO CANCEL</b></span></p>
<p class="p11"><span class="s1"> </span></p>
<p class="p7"><span class="s1">Check the confirmation email you received, and it will give instructions on how to cancel and reschedule. If you have difficulty, call the Lehi FamilySearch Center (801) 356-5820 and the receptionist will help you.</span></p>
<p class="p7"><span class="s1"> </span></p>
<p class="p7"><span class="s1">If you have any questions, you can call the center at 801-356-5820.</span></p>
<p class="p7"><span class="s1"> </span></p>
<p class="p7"><span class="s1">Thank you,</span></p>
<p class="p7"><span class="s1"> </span></p>
<p class="p7"><span class="s1">Elder and Sister Ross</span></p>
<p class="p7"><span class="s1">Missionaries</span></p>
<p class="p7"><span class="s1">Lehi FamilySearch Center </span></p>
</body>
</html>`},
        {
            title: 'Information',
            subject: 'Lehi FamilySearch Center Discovery Information',
            content: `<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">
<html>
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  <meta http-equiv="Content-Style-Type" content="text/css">
  <title></title>
  <meta name="Generator" content="Cocoa HTML Writer">
  <meta name="CocoaVersion" content="2113.6">
  <style type="text/css">
    p.p1 {margin: 0.0px 0.0px 13.0px 0.0px; font: 16.0px Helvetica; -webkit-text-stroke: #000000}
    p.p2 {margin: 0.0px 0.0px 0.0px 0.0px; font: 13.0px Helvetica; color: #181817; -webkit-text-stroke: #181817; min-height: 16.0px}
    p.p3 {margin: 0.0px 0.0px 0.0px 0.0px; font: 16.0px Arial; color: #181817; -webkit-text-stroke: #181817}
    p.p4 {margin: 0.0px 0.0px 0.0px 0.0px; font: 14.7px Helvetica; color: #181817; -webkit-text-stroke: #181817; min-height: 17.0px}
    p.p5 {margin: 0.0px 0.0px 0.0px 0.0px; font: 16.0px Arial; color: #094fd1; -webkit-text-stroke: #094fd1}
    span.s1 {font-kerning: none}
    span.s2 {text-decoration: underline ; font-kerning: none}
    span.s3 {font: 14.7px Arial; font-kerning: none; color: #181817; -webkit-text-stroke: 0px #181817}
  </style>
</head>
<body>
<p class="p1"><span class="s1">{name},</span></p>
<p class="p2"><span class="s1"></span><br></p>
<p class="p3"><span class="s1">Click on the link below for important Discovery video preview and information:</span></p>
<p class="p4"><span class="s1"></span><br></p>
<p class="p5"><span class="s2"><a href="https://office365lds.sharepoint.com/:v:/s/LehiFamilySearchCenter/EScwb_ed0S1KimE1fMXVr2sB8x0n_OArknDB50unAP6JFQ?e=HqruQM">Discovery-4.mp4</a></span><span class="s3">   </span></p>
<p class="p4"><span class="s1"></span><br></p>
<p class="p3"><span class="s1">Thank you and we are looking forward to you and your group's visit,</span></p>
<p class="p2"><span class="s1"></span><br></p>
<p class="p3"><span class="s1">Elder and Sister Ross</span></p>
<p class="p3"><span class="s1">Missionaries</span></p>
<p class="p3"><span class="s1">Lehi FamilySearch Center</span></p>
</body>
</html>
`
        },
    ],
    Text: [
        {
            title: 'Text Test',
            content: `This is a text to {name} for {organization}`
        }
    ]
}