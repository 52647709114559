import { Modal, Title } from '@mantine/core';
import { eventDetails } from './CalendarRow'
import { useAtom, useAtomValue } from 'jotai'
import { selectedEventAtom, currentViewAtom } from './state'

export default function MonthDetails() {
    const [event, setEvent] = useAtom(selectedEventAtom)
    const currentView = useAtomValue(currentViewAtom)

    if (!event || currentView !== 'dayGridMonth') {
        return null
    }

    const closeDetail = () => setEvent(null)
    const details = eventDetails(event.extendedProps.description, event.start, event.end)

    return (
        <Modal opened={!!event} onClose={closeDetail} centered overlayOpacity={0.5} size='70%' shadow='md'>
            <Title order={4} mt={-24} align='center'>{event.title}</Title>
            {details}
        </Modal>
    );
}