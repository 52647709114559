import { Group, Stack, Box, Button, Text, CloseButton, Menu } from '@mantine/core';
import { messages } from './messages';

export const isCanceled = title => title && title.match(/^CANCELL?ED/i)

export default function CalendarRow({ eventInfo, showDetails, closeDetail }) {
    let { title, type, backgroundColor, textColor, description, start, end } = eventInfo
    title = title.replace(/_/g, ' - ')
    if (type.startsWith('Classroom')) {
        type += ((title.startsWith('Class -') || title.startsWith('Clase -')) ? ' Class ' : ' Guest')
    }
    let borderColor = backgroundColor
    if (isCanceled(title)) {
        backgroundColor = undefined
        textColor = undefined
    }
    let details = null
    if (description && showDetails) {
        details = eventDetails(description, start, end)
    }
    const clickDetails = () => showDetails && closeDetail()
    return (
        <>
            <Group noWrap onClick={clickDetails} spacing={0} sx={{ cursor: 'pointer' }}>
                <Text align='center' weight={500} px={4} mr={16} my={-4} style={{ minWidth: 160, width: 160, display: 'inline-block', border: '2px solid', backgroundColor, color: textColor, borderColor }}>{type}</Text>
                <Group position='apart' sx={{ flexGrow: 1 }}>
                    <Text weight={400}>{title}</Text>
                    {details && <CloseButton onClose={closeDetail} size='lg' />}
                </Group>
            </Group>
            {details}
        </>
    )
}

export function eventDetails(description = '', start, end) {
    let details = null
    if (description.match(/<\//)) {
        // html-blob
        description = description.replace(/<html-blob><br><\/html-blob>/g, '')
        details = <Box my={8} dangerouslySetInnerHTML={{ __html: description }} />
    } else {
        description = description.replace(/\n{3,}/g, '\n\n')
        const groupTypeMatch = description.match(/Group Type: (.*)/)
        if (groupTypeMatch) {
            // setup email and text buttons in Discovery details
            const guestMatch = description.match(/(.*)\s+\((.*@.*)\)/)
            const [, name, email] = guestMatch
            const phone = description.match(/Phone:\s+(.*)/)[1]
            const groupType = groupTypeMatch[1]
            const group = description.match(/Group:\s+(.*)/)[1]
            const organization = `${group.replace(/\sgroup/i, '')} ${groupType.replace(/\sgroup/i, '')}`
            const datetime = `${start.toLocaleDateString('en-us', { weekday: "long", month: "long", day: "numeric", hour: "numeric" })} - ${end.toLocaleDateString('en-us', { hour: "numeric" }).split(', ')[1]}`
            const customizeContent = content => content.replace(/{name}/g, name).replace(/{organization}/g, organization).replace(/{datetime}/g, datetime)
            const sendEmail = message => {
                window.location.href = `mailto:${email}?subject=${message.subject}&Content-Type=text/html`;
                // copy to clipboard
                const content = customizeContent(message.content)
                const blob = new window.Blob([content], { type: 'text/html' });
                const clipboardItem = new window.ClipboardItem({ 'text/html': blob });
                navigator.clipboard.write([clipboardItem]);
            }
            const sendText = message => window.location.href = `sms:${phone};?&body=${window.encodeURI(customizeContent(message.content))}`
            description = description
                .replace(/\n/g, '<br/>')
                .replace(/Phone:\s(?:1-)?([0-9]+)/, (match, p1) => 'Phone: ' + p1.replace(/[^0-9]/, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'))
            details = (
                <Stack>
                    <Group mt={16}>
                        <SendMessage type='Email' sendMessage={sendEmail} />
                        <SendMessage type='Text' sendMessage={sendText} />
                    </Group>
                    <Box my={8} dangerouslySetInnerHTML={{ __html: description }} />
                </Stack>
            )
        } else {
            details = <Box my={8} sx={{ whiteSpace: 'pre-line' }}>{description}</Box>
        }

    }
    return details
}

function SendMessage({ type, sendMessage }) {
    const icon = (<span style={{ marginLeft: -6 }}><svg width="18" height="18" viewBox="0 0 320 512" fill="currentcolor"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" /></svg></span>)
    return (
        <Menu shadow="md" width={200}>
            <Menu.Target>
                <Button variant='outline' rightIcon={icon}>{type}</Button>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>{`Send ${type}`}</Menu.Label>
                {messages[type].map(message => <Menu.Item onClick={() => sendMessage(message)}>{message.title}</Menu.Item>)}
            </Menu.Dropdown>
        </Menu>
    )
}