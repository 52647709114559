import { useState } from 'react'
import { Group, TextInput, Checkbox, Text } from '@mantine/core';
import { useToggle } from '@mantine/hooks';
import CalendarRow from './CalendarRow'
import { createPlugin, formatDate } from '@fullcalendar/core';
import { calendarColorMap } from './calendars'
import { IconSearch } from '@tabler/icons';

function SearchView(props) {
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedEvent, setSelectedEvent] = useState()
    const [showPastEvents, toggleShowPastEvents] = useToggle()

    let { defs, instances } = props.eventStore
    const date = new Date()
    const now = date.getTime()
    const adjustedNow = now - (date.getTimezoneOffset() * 60 * 1000)
    instances = Object.values(instances)
        .sort((a, b) => a.range.start - b.range.start)
        .filter(instance => {
            if (!showPastEvents) {
                // don't show past events
                if (instance.range.end.getTime() < adjustedNow) {
                    return false
                }
            }
            return defs[instance.defId].title.toLowerCase().includes(searchTerm)
        })
    // console.dir({ props, instances })
    const changed = e => setSearchTerm(e.target.value.toLowerCase())
    return (
        <>
            <Group mb={16} spacing={20}>
                <TextInput
                    onChange={changed}
                    type="search"
                    label='Search'
                    labelProps={{ size: 'md' }}
                    id="default-search"
                    icon={<IconSearch size={16} />}
                    placeholder="Search Events"
                    sx={{ width: 300 }}
                />
                <Checkbox mt={20} size='md' label='Show past events' checked={!!showPastEvents} onClick={() => toggleShowPastEvents()} sx={{ cursor: 'pointer' }} />
            </Group>

            {instances.map(instance => {
                const date = new Date(instance.range.start.toUTCString().replace(' GMT', ''))
                const { title } = defs[instance.defId]
                const { description } = defs[instance.defId].extendedProps
                let { backgroundColor, textColor } = props.eventUiBases[instance.defId]
                const type = calendarColorMap[backgroundColor]
                const eventInfo = { title, type, backgroundColor, textColor, description, start: instance.range.start, end: instance.range.end }
                const eventId = date + title
                const showDetails = eventId === selectedEvent

                const opacity = instance.range.end.getTime() < adjustedNow ? 0.4 : 1

                return (
                    <Group onClick={() => setSelectedEvent(eventId === selectedEvent ? null : eventId)} p={4} sx={{ opacity, borderBottom: '1px solid lightgray' }} key={instance.instanceId}>
                        <Text sx={{ width: 112 }}>{formatDate(date, { weekday: 'short', month: 'short', day: 'numeric' })}</Text>
                        <Text sx={{ width: 80 }}>{formatDate(date, { hour: 'numeric', minute: '2-digit', meridiem: 'short', hour12: true })}</Text>
                        <div>
                            <CalendarRow eventInfo={eventInfo} showDetails={showDetails} />
                        </div>
                    </Group>
                )
            }
            )}
        </>
    )
}

export default createPlugin({
    views: {
        search: SearchView
    }
});
