import { useState, useEffect, createRef } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!
import { Group, LoadingOverlay, Modal } from '@mantine/core'
import { DatePicker } from '@mantine/dates';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import iCalendarPlugin from '@fullcalendar/icalendar'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import { calendarMap } from './calendars'
import searchViewPlugin from './searchView'
import './Calendar.css'
import CalendarRow, { isCanceled } from './CalendarRow'
import MonthDetail from './MonthDetail'
import { selectedEventAtom, currentViewAtom } from './state'
import { useSetAtom } from 'jotai'
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

const hoursMap = {
    'Morning': { start: 9, end: 13 },
    'Afternoon': { start: 13, end: 17 },
    'Evening': { start: 17, end: 21 },
}

let ShowCanceled, Shifts
let lastShift;

export default function Calendar({ calendars, showCanceled, shifts }) {
    const [showLoader, setShowLoader] = useState()
    const [showSelect, setShowSelect] = useState()
    const [print, setPrint] = useState()
    const [selectedEvent, setSelectedEvent] = useState()
    const setMonthEvent = useSetAtom(selectedEventAtom)
    const setCurrentView = useSetAtom(currentViewAtom)
    const calendarRef = createRef()

    ShowCanceled = showCanceled
    Shifts = shifts

    calendars = calendars.map(calendar => calendarMap[calendar])
    // console.log("calendars:", calendars)
    const win = typeof window !== "undefined" ? window : {}
    const location = win.location
    const host = location.origin.replace('3000', '8888')
    const setPrintMedia = e => setPrint(e.matches)
    const closeDetail = () => {
        setSelectedEvent(null)
        setMonthEvent(null)
    }
    const hideSelect = () => setShowSelect(false)

    useEffect(() => {
        let mediaQuery = window.matchMedia('print');
        mediaQuery.addEventListener('change', setPrintMedia);
        // this is the cleanup function to remove the listener
        return () => mediaQuery.removeEventListener('change', setPrint);
    }, []);

    const handleDate = date => {
        calendarRef.current.getApi().gotoDate(date)
        setShowSelect(false)
    }

    return (
        <>
            <LoadingOverlay visible={showLoader} />
            <FullCalendar
                ref={calendarRef}
                initialView="listDay"
                plugins={[iCalendarPlugin, dayGridPlugin, listPlugin, bootstrap5Plugin, searchViewPlugin, momentTimezonePlugin]}
                headerToolbar={print ?
                    { start: '', center: '', end: '' } :
                    { start: 'title', center: 'prev,today,next gotodate listDay,listWeek,dayGridMonth,search', end: '' }
                }
                titleFormat={{ month: 'long', day: 'numeric', weekday: 'short' }}
                buttonText={{
                    today: 'Today',
                    month: 'Month',
                    listDay: 'Day',
                    listWeek: 'Week',
                    search: 'Search',
                }}
                customButtons={{
                    gotodate: {
                        icon: 'calendar', click: () => {
                            setShowSelect(true)
                        }
                    }
                }}
                eventSources={calendars.map(calendar => ({
                    url: host + '/.netlify/functions/getical?url=' + calendar.url,
                    color: calendar.color,
                    backgroundColor: calendar.color,
                    id: calendar.title,
                    format: 'ics',
                    textColor: calendar.textColor,
                }))}
                // displayEventEnd={false}
                progressiveEventRendering={true}
                showNonCurrentDates={false}
                loading={setShowLoader}
                noEventsContent=''
                contentHeight='auto'
                listDayFormat={{ month: 'long', day: 'numeric', weekday: 'short', year: 'numeric' }}
                listDaySideFormat={false}
                viewDidMount={view => setCurrentView(view.view.type)}
                timeZone='America/Denver'

                eventOrder='start'
                views={{
                    list: {
                        eventContent: (eventInfo) => renderEventContent(eventInfo, equalEvents(eventInfo.event, selectedEvent), closeDetail),
                        titleFormat: { month: 'short', day: 'numeric', weekday: 'short' },
                    },
                    month: {
                        eventClassNames: arg => 'cursor-pointer',
                        titleFormat: { month: 'long' },

                    },
                    search: {
                        titleFormat: { year: 'numeric' },
                    }
                }}
                themeSystem='bootstrap5'
                eventClick={eventInfo => {
                    if (!equalEvents(eventInfo.event, selectedEvent)) {
                        setMonthEvent(eventInfo.event)
                        setSelectedEvent(eventInfo.event)
                        // console.dir(eventInfo)
                    }
                    return true
                }}
                eventClassNames={arg => {
                    let className = ''
                    if (arg.event.end.getTime() < (new Date()).getTime()) {
                        className = 'opacity-40'
                    }

                    // shift divider?
                    if (Shifts === 'All') {
                        const start = new Date(arg.event.start)
                        const shift = getShift(start)
                        if (shift !== 'Morning' && shift !== lastShift) {
                            className += ' shift-border'
                        }
                        lastShift = shift
                    }

                    return className
                }}
            />
            {<MonthDetail />}
            <Modal opened={showSelect} onClose={hideSelect} overlayOpacity={0.5} shadow='md' size='sm' title='Go to date'>
                <Group position="center">
                    <DatePicker weekendDays={[]} firstDayOfWeek={0} value={calendarRef.current ? calendarRef.current.getApi().getDate() : new Date()} size='md' onChange={handleDate} />
                </Group>
            </Modal>
        </>
    )
}

const equalEvents = (ev1, ev2) => ev1 && ev2 && (ev1.start + ev1.title === ev2.start + ev2.title)

function renderEventContent({ event, backgroundColor, textColor }, showDetails, closeDetail) {
    const eventInfo = {
        title: event.title,
        type: event.source.id,
        backgroundColor,
        textColor, description: event.extendedProps.description,
        start: event.start,
        end: event.end,
    }
    return (<CalendarRow eventInfo={eventInfo} showDetails={showDetails} closeDetail={closeDetail} />)
}

function filterEvents(data) {
    let events = data.rawEvents
    if (!ShowCanceled) {
        events = events.filter(event => !isCanceled(event.title))
    }
    if (Shifts !== 'All') {
        events = events.filter(event => getShift(new Date(event.start)) === Shifts)
    }
    const result = { ...data, rawEvents: events }
    return result
}

function getShift(date) {
    let hour = date.getHours()
    const dow = date.getDay()
    if (dow === 0 || dow === 6) {
        hour--  // Sunday and Saturday are an hour later
    }
    return Object.keys(hoursMap).find(shift => hour >= hoursMap[shift].start && hour < hoursMap[shift].end)
}

// patch iCalendarPlugin so we can do some caching
// console.log('iCalendarPlugin', iCalendarPlugin)
const cache = {}
let patched
if (!patched) {
    const originalFetch = iCalendarPlugin.eventSourceDefs[0].fetch
    const myFetch = (arg, onSuccess, onFailure) => {
        const { eventSource } = arg
        const key = eventSource._raw.url

        const myOnSuccess = data => {
            onSuccess(filterEvents(data))
            // save in cache
            cache[key] = data
            // console.log("caching:", key)
        }

        const value = cache[key]
        if (value) {
            // already in cache
            onSuccess(filterEvents(value))
            // console.log("used cache:", key)
            // console.log(value)
        } else {
            // console.log('fetching:', arg)
            const myArg = { ...arg }
            const now = new Date()
            const year = now.getFullYear()
            const month = now.getMonth()
            myArg.range = { start: new Date(year - 1, month), end: new Date(year + 1, month) }
            originalFetch(myArg, myOnSuccess, onFailure)
        }
    }
    iCalendarPlugin.eventSourceDefs[0].fetch = myFetch
    patched = true
    // console.log("patched iCalendarPlugin")
}
